import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { createRouter } from './router';
import { createStore } from './store';
import { notEmpty, downloadUrlFile } from '@/utils/index';
import * as filters from './filters';
import { richToText } from './utils';
// 添加垫片
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Pagination from '@/components/Pagination';
// import { sync } from 'vuex-router-sync';

import 'style/reset.css';
import 'style/common.scss';
import 'style/style.scss';
import 'style/manage.scss';

// 挂载过滤器
for (const attr in filters) {
  Vue.filter(attr, filters[attr]);
}
Vue.prototype.notEmpty = notEmpty;
Vue.prototype.downloadUrlFile = downloadUrlFile;
Vue.prototype.richToText = richToText;
Vue.component('Pagination', Pagination);
Vue.use(ElementUI);

export default function createApp() {
  // 创建 router 和 store 实例
  const router = createRouter();
  const store = createStore();
  Vue.use(ElementUI);
  // 同步路由状态(route state)到 store
  // sync(store, router);

  // 创建应用程序实例，将 router 和 store 注入
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  });

  // 暴露 app, router 和 store
  return { app, router, store };
}
