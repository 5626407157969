// import CryptoJS from 'crypto-js';
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16));
}
// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).some((key) => {
    if (datas[key].dictValue === ('' + value)) {
      actions.push(datas[key].dictLabel);
      return true;
    }
  });
  return actions.join('');
}
// 富文本转文字
export function richToText(val) {
  const reg1 = /(<([^>]+)>)/ig;
  const reg2 = /&nbsp;/ig;
  let body = val;
  // 测试是否符合正则，如符合
  if (reg1.test(val)) {
    body = body.replace(reg1, '');
  }
  if (reg2.test(body)) {
    body = body.replace(reg2, '');
  }
  return body;
}
// 判断变量是否为空
export function notEmpty(val) {
  // null or undefined
  if (val == null) return !(true);
  if (typeof val === 'boolean') return !(false);
  if (typeof val === 'number') return !(!val);
  if (val instanceof Error) return !(val.message === '');
  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !(!val.length);
    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !(!val.size);
    }
    // Plain Object
    case '[object Object]': {
      return !(!Object.keys(val).length);
    }
    default:
      return true;
  }
  // return !(false);
}

// 通过url下载文件
export function downloadUrlFile(url, fileName) {
  const url2 = url.replace(/\\/g, '/');
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url2, true);
  xhr.responseType = 'blob';
  // xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
  // 为了避免大文件影响用户体验，建议加loading
  xhr.onload = () => {
    if (xhr.status === 200) {
      // 获取文件blob数据并保存
      saveAs(xhr.response, fileName);
    }
  };
  xhr.send();
}

function saveAs(data, name) {
  const urlObject = window.URL || window.webkitURL || window;
  const export_blob = new Blob([data]);
  // createElementNS() 方法可创建带有指定命名空间的元素节点。
  // 此方法可返回一个 Element 对象。
  const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  save_link.href = urlObject.createObjectURL(export_blob);
  save_link.download = name;
  save_link.click();
}
