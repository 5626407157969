import Vue from 'vue';
import Vuex from 'vuex';

import { getConfig, getNewsInfo } from '@/service/api';
// 使用vuex-persistedstate 插件

Vue.use(Vuex);

const VueStore = {
  state: {
    token: '',
    topActive: 0,
    navIds: null,
    navItems: null,
    config: {},
    newsInfo: {}
  },
  mutations: {
    setTopActive(state, parm) {
      state.topActive = parm;
    },
    setToken(state, parm) {
      state.token = parm;
    },
    setNavIds(state, parm) {
      state.navIds = parm;
    },
    setNavItems(state, parm) {
      state.navItems = parm;
    },
    getConfig(state, param) {
      state.config = param;
    },
    getNewsInfo(state, param) {
      state.newsInfo = param;
    }
  },
  actions: {
    getConfig({ commit }) {
      return getConfig().then((res) => {
        commit('getConfig', res.data);
      }).catch(() => {});
    },
    getNewsInfo({ commit }, id) {
      return getNewsInfo(id).then((res) => {
        commit('getNewsInfo', res.data);
      }).catch(() => {});
    }
  },
  modules: {
  }
};

export function createStore() {
  return new Vuex.Store(VueStore);
}

export default new Vuex.Store(VueStore);
