import Vue from 'vue';
import VueRouter from 'vue-router';
// 层级页面
import Layout from '@/layout/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    meta: '首页',
    component: Layout,
    children: [{
      path: '/',
      name: 'Index',
      component: () => import('@/views/home/Index.vue')
    }]
  },
  {
    path: '/jiayuan',
    meta: '嘉园体系',
    component: Layout,
    children: [{
      path: '/',
      name: 'Jiayuan',
      component: () => import('@/views/jiayuan/Index.vue')
    }]
  },
  {
    path: '/smartWard',
    meta: '智慧病房',
    component: Layout,
    children: [{
      path: 'ward',
      name: 'Ward',
      component: () => import('@/views/smartWard/ward.vue')
    }, {
      path: 'bedside',
      name: 'Bedside',
      component: () => import('@/views/smartWard/bedside.vue')
    }, {
      path: 'nurse',
      name: 'Nurse',
      component: () => import('@/views/smartWard/nurse.vue')
    }]
  },
  {
    path: '/smartGovernment',
    meta: '智慧政务',
    component: Layout,
    children: [{
      path: 'publicService',
      name: 'PublicService',
      component: () => import('@/views/solution/publicService.vue')
    }, {
      path: 'certificate',
      name: 'Certificate',
      component: () => import('@/views/solution/certificate.vue')
    }, {
      path: 'vision',
      name: 'Vision',
      component: () => import('@/views/solution/vision.vue')
    }]
  },
  // {
  //   path: '/product',
  //   meta: '核心产品',
  //   component: Layout,
  //   children: [{
  //     path: 'iot',
  //     name: 'Iot',
  //     component: () => import('@/views/product/Index.vue')
  //   }, {
  //     path: 'cim',
  //     name: 'Cim',
  //     component: () => import('@/views/product/Index.vue')
  //   }]
  // },
  {
    path: '/solution',
    meta: '解决方案',
    component: Layout,
    children: [
      {
        path: 'scheme',
        name: 'Scheme',
        component: () => import('@/views/solution/Scheme.vue')
      },
      {
        path: 'chengtou',
        name: 'Chengtou',
        component: () => import('@/views/solution/chengtou.vue')
      },
      {
        path: 'health',
        name: 'Health',
        component: () => import('@/views/solution/health.vue')
      },
      {
        path: 'mobility',
        name: 'Mobility',
        component: () => import('@/views/solution/mobility.vue')
      },
      {
        path: 'space',
        name: 'Space',
        component: () => import('@/views/solution/Index.vue')
      },
      //  {
      //   path: 'city',
      //   name: 'City',
      //   component: () => import('@/views/solution/Index.vue')
      // }, {
      //   path: 'village',
      //   name: 'Village',
      //   component: () => import('@/views/solution/Index.vue')
      // }, {
      //   path: 'city/item',
      //   name: 'CityItem',
      //   component: () => import('@/views/solution/Second1.vue')
      // }, {
      //   path: 'village/item',
      //   name: 'VillageItem',
      //   component: () => import('@/views/solution/Second1.vue')
      // },
      {
        path: 'space/item',
        name: 'SpaceItem',
        component: () => import('@/views/solution/Second1.vue')
      }
    ]
  },
  {
    path: '/news',
    meta: '资讯动态',
    component: Layout,
    children: [{
      path: '/',
      name: 'News',
      component: () => import('@/views/news/Index.vue')
    }, {
      path: 'info',
      name: 'NewsINfo',
      component: () => import('@/views/news/newsInfo.vue')
    }]
  },
  {
    path: '/about',
    meta: '关于中嘉',
    component: Layout,
    children: [{
      path: 'introduce',
      name: 'About',
      component: () => import('@/views/about/Index.vue')
    }, {
      path: 'connect',
      name: 'Connect',
      component: () => import('@/views/about/connect.vue')
    }, {
      path: 'recruitment',
      name: 'Recruitment',
      component: () => import('@/views/about/recruitment.vue')
    }, {
      path: 'recruitment/info',
      name: 'RecruitmentInfo',
      component: () => import('@/views/about/recruitmentInfo.vue')
    }]
  },
  {
    path: '/freeTrial',
    meta: '免费咨询',
    component: Layout,
    children: [{
      path: '/',
      name: 'FreeTrial',
      component: () => import('@/views/freeTrial/Index.vue')
    }]
  },
  {
    path: '/cooperation',
    meta: '生态合作',
    component: Layout,
    children: [{
      path: '/',
      name: 'Cooperation',
      component: () => import('@/views/cooperation/Index.vue')
    }, {
      path: 'joinUs',
      name: 'JoinUs',
      component: () => import('@/views/cooperation/joinUs.vue')
    }]
  },
  {
    path: '/login',
    meta: '登录',
    component: () => import('@/views/login/index.vue'),
    name: 'Login',
    hidden: true
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { y: 0 };
  }
});

// ssr 输出
export function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
      return { y: 0 };
    }
  });
}

export default router;
