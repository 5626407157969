import http from './http';

// 首页轮播图
export function getBannerList(query) {
  console.log('getBannerList=======================>');
  return http({
    url: '/banner/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: query
  });
}
// 首页资讯动态
export function getHomeNews(query) {
  console.log('getHomeNews=======================>');
  return http({
    url: '/article/find/33',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: query
  });
}
// 员工心声
export function getStaffSound(query) {
  return http({
    url: '/article/queryArticleList?navId=45',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: query
  });
}
// 资讯动态
export function getNewsList(query) {
  return http({
    url: '/article/list',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: query
  });
}
// 资讯动态-详情
export function getNewsInfo(id) {
  return http({
    url: `/article/info/${id}`,
    method: 'get'
  });
}
// 资讯动态-上一篇下一篇
export function fetchPreNext(id) {
  return http({
    url: `article/fetchPreNext/${id}`,
    method: 'get'
  });
}
// 资讯动态-热门资讯
export function popularArticleList() {
  return http({
    url: `/article/popularArticleList`,
    method: 'get'
  });
}
// 网站配置
export function getConfig(query) {
  return http({
    url: '/config/view',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: query
  });
}
// 导航栏列表
export function getNavList(query) {
  return http({
    url: '/nav/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: query
  });
}
// 导航栏列表
export function getRecruitmentList(query) {
  return http({
    url: '/recruitment/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: query
  });
}
// 合作申请保存接口
export function saveCooperationForm(query) {
  return http({
    url: '/cooperationForm/save',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: getString(query)
  });
}

// 合作申请保存接口
export function insertApply(query) {
  return http({
    url: '/try/apply/insert',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: getString(query)
  });
}

// 合作申请保存接口
export function getRecruitmentInfo(id) {
  return http({
    url: `/recruitment/info/${id}`,
    method: 'get'
  });
}
// 获取解决方案页面信息
export function getNavListConfig(id) {
  return http({
    url: `/nav/listConfig?navId=${id}`,
    method: 'get'
  });
}
// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return http({
    url: '/index/type/' + dictType,
    method: 'get'
  });
}

function getString(query) {
  let url = '';
  for (const propName of Object.keys(query)) {
    const value = query[propName];
    var part = encodeURIComponent(propName) + '=';
    if (value !== null && typeof (value) !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          const params = propName + '[' + key + ']';
          var subPart = encodeURIComponent(params) + '=';
          url += subPart + encodeURIComponent(value[key]) + '&';
        }
      } else {
        url += part + encodeURIComponent(value) + '&';
      }
    }
  }
  return url;
}
