import axios from 'axios';
import { Message } from 'element-ui';
import errorCode from './errorCode';

// vm.$router.push('/login');
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 300000
});

http.interceptors.request.use( // 请求拦截
  config => {
    return config;
  },
  error => {
    // 对请求错误做些什么
    Message.error('请求超时!');
    return Promise.reject(error);
  }
);

http.interceptors.response.use( // 响应拦截器
  async response => {
    const status = Number(response.code) || 200;
    const res = response.data;
    const message = res.msg || errorCode[status] || errorCode.default;

    if (status === 401 || (res && res.code && res.code === 401)) {
      return null;
    }

    if (status !== 200 || (res && res.code && res.code !== 200)) {
      Message.error(message);
      return Promise.reject(message);
    }
    return res;
  },
  error => { // 错误码
    console.log('错误码', error, error.response);
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);

export default http;

