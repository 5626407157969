// 返回当前的年月日
export function timeFormatFn(date) {
  if (date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }
}

// 时间变成两位数
function toTwo(n) {
  return +n < 10 ? '0' + n : n + '';
}

// 正常化日期
function normalDate(oDate) {
  var reg = /-+/g;

  if (typeof (oDate) === 'string') {
    oDate = oDate.split('.')[0]; // 解决ie浏览器对yyyy-MM-dd HH:mm:ss.S格式的不兼容
    oDate = oDate.replace(reg, '/'); // 解决苹果浏览器对yyyy-MM-dd格式的不兼容性
  }

  oDate = new Date(oDate);
  return oDate;
}

// 日期格式化函数
// oDate（时间戳或字符串日期都支持）
// fmt（格式匹配）
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
/*
    例子：
    dateFormat0(new Date(),'yyyy-MM-dd hh:mm:ss.S'); //2018-12-21 17:24:33.664
    dateFormat0(new Date(),'y-M-d h:m:s.S/q'); //2018-12-21 17:24:33.666/4
*/
export function dateFormat0(oDate, fmt) {
  fmt = fmt || 'yyyy-MM-dd hh:mm:ss';
  oDate = normalDate(oDate || new Date());
  var date = {
    'y+': oDate.getFullYear(), // 年
    'M+': oDate.getMonth() + 1, // 月
    'd+': oDate.getDate(), // 日
    'h+': oDate.getHours(), // 时
    'm+': oDate.getMinutes(), // 分
    's+': oDate.getSeconds(), // 秒
    'S': oDate.getMilliseconds(), // 毫秒
    'q+': Math.ceil((oDate.getMonth() + 1) / 3) // 季度
  };
  var result = '';
  var value = '';

  for (var attr in date) {
    if (new RegExp('(' + attr + ')').test(fmt)) {
      result = RegExp.$1;
      value = date[attr] + '';
      fmt = fmt.replace(result, result.length === 1 ? value : (attr === 'y+' ? value.substring(4 - result.length) : toTwo(value)));
    }
  }
  return fmt;
}
