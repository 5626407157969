import { dateFormat0 } from '@/service/common';

const date = (value, format) => {
  if (!value && value !== 0) return;
  return dateFormat0(value, format);
};

export {
  date // 时间过滤器
};
