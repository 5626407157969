<template>
  <div id="backTop" :class="{'back-top': true, 'hide': isShow}" @click="goTop($event)">
    <div class="back-top-icon">
      <img :src="require('img/back_top.png')" alt="backTop">
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackTop',
  data() {
    return {
      isShow: true,
      scrollTop: '' // 滚动距离
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
  methods: {
    // 回到顶部
    goTop(e) {
      // 当前父级元素
      const topEle = e.currentTarget.parentElement;
      console.log('e.currentTarget.parentElement', e.currentTarget.parentElement);
      topEle.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    scrollToTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      const curEle = document.getElementById('backTop');
      // 当前元素距离页面顶部距离
      const curEleTop = curEle.offsetTop - document.documentElement.scrollTop;
      if (this.scrollTop > curEleTop) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.back-top {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  position: fixed;
  bottom: 10%;
  right: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .back-top-icon {
    width: 100%;
    height: 100%;
  }
  &.hide {
    visibility: hidden;
  }
}
</style>
