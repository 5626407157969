<template>
  <div class="top-nav">
    <div class="logo" @click="$router.push('/')">
      <img :src="require('@/assets/img/logo.svg')" alt="logo">
    </div>
    <ul class="nav-list">
      <li
        v-for="item in menuItems"
        :key="item.id"
        class="nav-item"
        :class="{'has-child': item.child.length, 'active': isActiveMainItem(item)}"
        :style="{
          marginLeft: item.marginLeft
        }"
        @mouseenter="showSubmenu(item)"
        @mouseleave="hideSubmenu(item)"
        @click="navigate(item)"
      >
        <div class="nav-link">
          {{ item.name }}
          <img v-if="item.child.length && item.name !== '新闻资讯'" class="arrow" src="@/assets/img/arrow-down-black.png" alt="">
        </div>
        <ul v-if="item.child.length && item.name !== '新闻资讯'" class="submenu">
          <li v-for="child in item.child" :key="child.id" class="submenu-item" :class="{'active': isActiveSubItem(child)}" @click.stop="navigate(child)">
            <div class="submenu-link">
              {{ child.name }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
// import { getNavList } from '@/service/api';
export default {
  name: 'TopNav',
  data() {
    return {
      menuItems: [], // 初始化菜单项
      currentPath: this.$route.path // 当前路由路径
    };
  },
  async created() {
    this.menuItems = await this.getMenuItems();
  },
  methods: {
    /**
     * 异步获取菜单项目。
     * @returns 返回一个包含菜单项目的数组。
     */
    async getMenuItems() {
      // 获取导航列表的异步操作
      // const { data } = await getNavList();
      const data = [
        {
          'desction': '首页',
          'name': '首页',
          'pathUrl': '/',
          'id': 1,
          'child': []
        },
        {
          'desction': '',
          'name': '智慧病房',
          'pathUrl': '/smartWard',
          'id': 49,
          'child': [
            {
              'desction': '',
              'name': '病房智能交互系统',
              'pathUrl': '/smartWard/ward',
              'id': 50,
              'child': []
            },
            {
              'desction': '',
              'name': '床旁智能交互系统',
              'pathUrl': '/smartWard/bedside',
              'id': 51,
              'child': []
            },
            {
              'desction': '',
              'name': '护士站智能交互系统',
              'pathUrl': '/smartWard/nurse',
              'id': 52,
              'child': []
            }
          ]
        },
        {
          'desction': '',
          'name': '智慧政务',
          'pathUrl': '/smartGovernment',
          'id': 53,
          'child': [
            {
              'desction': '',
              'name': '证照管理',
              'pathUrl': '/smartGovernment/certificate',
              'id': 55,
              'child': []
            },
            {
              'desction': '',
              'name': '远程虚拟窗口',
              'pathUrl': '/smartGovernment/vision',
              'id': 56,
              'child': []
            },
            {
              'desction': '',
              'name': '政务服务增值化',
              'pathUrl': '/smartGovernment/publicService',
              'id': 54,
              'child': []
            }
          ]
        },
        {
          'desction': null,
          'name': '新闻资讯',
          'pathUrl': '/news?type=0',
          'id': 33,
          'child': [
            {
              'desction': null,
              'name': '行业动态',
              'pathUrl': '/news?type=0',
              'id': 34,
              'child': []
            },
            {
              'desction': null,
              'name': '公司新闻',
              'pathUrl': '/news?type=1',
              'id': 35,
              'child': []
            },
            {
              'desction': null,
              'name': '观点分享',
              'pathUrl': '/news?type=3',
              'id': 37,
              'child': []
            }
          ]
        },
        {
          'desction': null,
          'name': '关于中嘉',
          'pathUrl': '/about',
          'id': 38,
          'child': [
            {
              'desction': null,
              'name': '公司介绍',
              'pathUrl': '/about/introduce',
              'id': 39,
              'child': []
            },
            {
              'desction': null,
              'name': '招贤纳士',
              'pathUrl': '/about/recruitment',
              'id': 40,
              'child': []
            },
            {
              'desction': null,
              'name': '联系我们',
              'pathUrl': '/about/connect',
              'id': 41,
              'child': []
            }
          ]
        },
        {
          'desction': null,
          'name': '免费试用',
          'pathUrl': '/freeTrial',
          'id': 46,
          'child': []
        },
        {
          'desction': null,
          'name': '生态合作',
          'pathUrl': '/cooperation',
          'id': 44,
          'child': []
        }
      ];
      const items = data;
      this.$store.commit('setNavIds', { ...items[3] });
      // 遍历每个菜单项进行初始化
      items.forEach(item => {
        this.$set(item, 'showSubmenu', false); // 初始化每个菜单项的子菜单显示状态
        // 如果有子菜单，则对子菜单也进行初始化
        if (item.child && item.child.length > 0) {
          item.child.forEach(child => {
            this.$set(child, 'showSubmenu', false);
          });
        }
        this.$set(item, 'marginLeft', '45px'); // 初始化菜单项的左边距
        // 判断是否为倒数第二个菜单项，以调整其左边距
        if (items.indexOf(item) === items.length - 2) {
          this.$set(item, 'marginLeft', 'auto');
          this.$set(item, 'marginRight', '0');
        }
        if (items.indexOf(item) === items.length - 1) {
          this.$set(item, 'marginLeft', '0');
        }
      });
      return items;
    },

    /**
     * 检查当前路由的查询参数是否与指定URL的查询参数匹配。
     * @param {string} pathUrl - 指定的URL。
     * @returns 返回一个布尔值，表示查询参数是否匹配。
     */
    isQueryParamsMatch(pathUrl) {
      // 如果URL中不包含查询参数，则认为匹配
      if (!pathUrl.includes('?')) return true;
      // 将URL的查询字符串转换为对象，并与当前路由的查询参数进行比较
      const queryParams = Object.fromEntries(new URLSearchParams(pathUrl.split('?')[1]));
      return Object.entries(queryParams).every(([key, value]) => this.$route.query[key] === value);
    },

    /**
     * 判断指定的一级菜单项是否处于激活状态。
     * @param {Object} item - 一级菜单项对象。
     * @returns 返回一个布尔值，表示一级菜单项是否激活。
     */
    isActiveMainItem(item) {
      // 直接比较路径是否匹配
      if (this.$route.path === item.pathUrl) return true;
      // 遍历子菜单项，如果任何一个子项的路径匹配当前路由，则认为激活
      return item.child && item.child.some(child => this.$route.path === child.pathUrl.split('?')[0] && this.isQueryParamsMatch(child.pathUrl));
    },

    /**
     * 判断指定的子菜单项是否处于激活状态。
     * @param {Object} item - 子菜单项对象。
     * @returns 返回一个布尔值，表示子菜单项是否激活。
     */
    isActiveSubItem(item) {
      // 分割URL以获取基础路径，并检查基础路径是否与当前路由匹配
      const childPath = item.pathUrl.split('?')[0];
      return this.$route.path === childPath && this.isQueryParamsMatch(item.pathUrl);
    },

    /**
     * 根据指定的菜单项进行页面跳转。
     * @param {Object} item - 菜单项对象。
     */
    navigate(item) {
      // 如果菜单项没有子菜单，则进行页面跳转
      if (!item.child || item.child.length === 0 || item.name === '新闻资讯') {
        this.$router.push(item.pathUrl);
      }
      // 对于有子项的一级菜单，不执行跳转逻辑，仅展开子菜单
    },

    /**
     * 显示指定菜单项的子菜单。
     * @param {Object} item - 菜单项对象。
     */
    showSubmenu(item) {
      // 设置子菜单为显示状态
      item.showSubmenu = true;
    },

    /**
     * 隐藏指定菜单项的子菜单。
     * @param {Object} item - 菜单项对象。
     */
    hideSubmenu(item) {
      // 设置子菜单为隐藏状态
      item.showSubmenu = false;
    }
  }
};
</script>

<style scoped lang="scss">
.top-nav {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1280px;
  height: 64px;

  .logo {
    margin: 5px 30px 0 0;
    width: 130px;
    height: auto;
    img {
      width: 100%;
      z-index: 1000;
    }
  }

  .nav-list {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;

    .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 45px;
      // 不允许换行
      white-space: nowrap;
      // hover和active时的样式
      &:hover,
      &.active {
        position: relative;
        .nav-link {
          color: #2469f1;
        }
      }
      // 仅hover时的样式
      &:hover {
        .nav-link {
          .arrow {
            // 旋转180度
            transform: rotate(180deg);
          }
        }
        .submenu {
          transform: scale(1) translateX(-50%);
        }
      }
      // 仅active时的样式
      &.active {
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #2469f1;
        }
      }

      .nav-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        // font-family: 'PingFang-Medium'!important;
        color: #333333;

        .arrow {
          margin-left: 5px;
          width: 8px;
          height: 8px;
          transition: all 0.2s;
        }
      }

      .submenu {
        // display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        list-style: none;
        padding: 10px 0;
        margin: 0;
        background-color: #fff;
        box-shadow: 0px 0px 4px 0px rgba(227,227,227,0.50);
        min-width: 100%;
        transition: all 0.1s ease;
        transform: scale(1, 0) translateX(-50%);
        transform-origin: top;
        // font-family: 'pingfang-Regular';

        .submenu-item {
          height: 32px;
          padding: 0 19px;
          font-size: 16px;
          // font-weight: 400;
          border-radius: 8px;
          margin-bottom: 5px;
          .submenu-link {
            cursor: pointer;
            display: flex; // 启用flex布局
            align-items: center;
            line-height: 32px;
            white-space: nowrap;
            color: #0b1d30;
            flex-grow: 1; // 允许它根据需要扩展
            .icon {
              width: 24px;
              height: 24px;
              margin-right: 12px;
              flex-shrink: 0; // 防止图标大小调整
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:hover,
          &.active {
            .submenu-link {
              color: #2469f1;
            }
          }
        }
      }

      &:hover .submenu,
      & .submenu[v-show="true"] {
        display: block;
      }
    }
  }
}

.nav-item:hover .nav-link .arrow,
.nav-item.active .nav-link .arrow {
  content: url('../../assets/img/arrow-down-blue.png'); /* 使用CSS背景方式替换 */
}
</style>
